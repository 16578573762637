import {useUserStore} from "~/store/user";

export default defineNuxtRouteMiddleware((to, from) => {
    if (!useUserStore().isLoggedIn && to.path !== '/connexion' && !to.path.includes('inscription/confirmation-compte')) {
        return navigateTo({name: 'connexion'});
    } else if (useUserStore().isLoggedIn && (to.path === '/connexion' || to.path.includes('inscription/confirmation-compte'))) {
        let newRoute;

        if (to.query?.redir) {
            newRoute = useRouter().getRoutes().map(r => r.path).includes(to.query.redir) ? {path: to.query.redir, } : {name: 'mon-compte'};
        } else {
            newRoute = {name: 'mon-compte'};
        }

        return navigateTo(newRoute);
    }
});